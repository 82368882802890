import { Alert, Anchor, Button, Container, Group, Paper, PasswordInput, Text, TextInput, Title } from '@mantine/core';
import { useForm } from '@mantine/form';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { FunctionComponent } from 'react';

import { useLogin } from '@app/hooks/useLogin';
import { Trans, useTranslation } from '@app/i18n';
import { TransLink } from '@components/Utilities/TransLink';
import { Roles } from '@utilities/Security/roles';

interface IFormValues {
  email: string;
  password: string;
}

export const LoginForm: FunctionComponent = () => {
  const router = useRouter();
  const [t] = useTranslation();
  const { error, loading, login } = useLogin('/app/dashboard', [Roles.STUDENT]);

  const form = useForm<IFormValues>({
    initialValues: {
      email: '',
      password: '',
    },
    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : t('validation:email')),
    },
  });

  return (
    <Container size={420} my={40} p={0}>
      <Title align="center" sx={{ fontWeight: 900 }}>
        {t('auth:login.headline')}
      </Title>
      <Text color="dimmed" size="sm" align="center" mt={5}>
        <Trans
          ns="auth"
          i18nKey="login.subheadline"
          components={{
            registerLink: <TransLink href="/account/register" />,
          }}
        />
      </Text>

      <Paper withBorder={true} shadow="md" p={30} mt={30} radius="md">
        {!!router.query?.sessionExpired && (
          <Alert color="yellow" mb="lg">
            {t('auth:login.sessionExpired')}
          </Alert>
        )}
        {error && (
          <Alert color="red" mb="lg">
            {t(`authError.${error}`)}
          </Alert>
        )}
        <form onSubmit={form.onSubmit((data) => login(data))}>
          <TextInput
            label={t('auth:login.form.fields.email.label')}
            {...form.getInputProps('email')}
            placeholder={t('auth:login.form.fields.email.placeholder')}
            required={true}
            autoComplete="username"
            type="email"
          />
          <PasswordInput
            label={t('auth:login.form.fields.password.label')}
            {...form.getInputProps('password')}
            placeholder={t('auth:login.form.fields.password.placeholder')}
            required={true}
            autoComplete="current-password"
            mt="md"
          />
          <Group position="apart" mt="md">
            <span></span>
            <Link href="/account/recover" passHref={true}>
              <Anchor<'a'> size="sm">{t('auth:login.forgotPassword')}</Anchor>
            </Link>
          </Group>
          <Button fullWidth mt="xl" type="submit" loading={loading}>
            {t('auth:login.form.submit')}
          </Button>
        </form>
      </Paper>
    </Container>
  );
};
