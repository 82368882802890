import { GetServerSideProps, NextPage } from 'next';
import Head from 'next/head';
import React from 'react';

import { LoginForm } from '@components/App/Auth/LoginForm';
import { getServerSidePropsCheckPreconditionsSSRFactory } from '@utilities/checkPreconditionsSSR';

const AppLogin: NextPage = () => {
  return (
    <>
      <Head>
        <title>Login</title>
      </Head>
      <LoginForm />
    </>
  );
};

export const getServerSideProps: GetServerSideProps = getServerSidePropsCheckPreconditionsSSRFactory({
  redirectIfAuthenticated: '/app/dashboard',
  skipNotAuthenticatedCheck: true,
});

export default AppLogin;
