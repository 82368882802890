import { Anchor, AnchorProps } from '@mantine/core';
import Link from 'next/link';
import React, { FunctionComponent } from 'react';

interface IProps extends AnchorProps {
  href: string;
}

export const TransLink: FunctionComponent<IProps> = ({ href, ...props }) => {
  return (
    <Link href={href} passHref={true}>
      <Anchor<'a'> {...props} />
    </Link>
  );
};
